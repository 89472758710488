




























































import Vue from 'vue';
import { applicationNameByCityCode } from '@/api/projectDetail';
import { mapGetters } from 'vuex';
const redFileUrl =
  'https://ysf.oss.yunsuanfang.com/product-public/redFile/index.html';
export default Vue.extend({
  name: 'redDocument',
  data() {
    return {
      redDocumentList: [
        {
          name: '关于发布2022年上半年综合用工指导价的通知 石建价信［2022］2号',
          url: `${redFileUrl}?type=2022_2`,
        },
        {
          name: '关于发布2021年下半年综合用工指导价的通知 石建价信［2022］1号',
          url: `${redFileUrl}?type=2022_1`,
        },
        {
          name: '关于发布唐山市2022年上半年综合用工指导价的通知 唐建价信［2022］7号',
          url: `${redFileUrl}?type=2022_7`,
        },
        {
          name: '关于发布建设工程2022年上半年综合用工指导价的通知 秦工笺［2022］3号',
          url: `${redFileUrl}?type=2022_3`,
        },
        {
          name: '关于发布保定市2022年上半年综合用工指导价的通知 保住建发［2022］410号',
          url: `${redFileUrl}?type=2022_410`,
        },
        {
          name: '关于发布2022年上半年建设工程综合用工指导价的通知 邢建价［2022］005号',
          url: `${redFileUrl}?type=2022_005`,
        },
        {
          name: '关于发布2019年建设工程综合用工指导价的通知（石建价信[2020]1号）',
          url: `${redFileUrl}?type=2020_1`,
        },
        {
          name: '关于发布2018年下半年建设工程综合用工指导价的通知（石建信[2019]1号）',
          url: `${redFileUrl}?type=2019_1`,
        },
        {
          name: '关于发布2018年上半年建设工程综合用工指导价的通知（石建信[2018]1号）',
          url: `${redFileUrl}?type=2018_1`,
        },
      ],
      secondDocumentFile: {
        name: '关于完善建筑企业规费计取有关工作的通知 冀建市〔2016〕11号文',
        url: `${redFileUrl}?type=2016_11`,
      },
      lastDocumentFile: {
        name: '关于调整安全生产文明施工费费率的通知 冀建工〔2017〕78号',
        url: `${redFileUrl}?type=2017_78`,
      },
    };
  },
  mounted() {
    setTimeout(() => {
      this.applicationNameByCityCode();
    }, 500);
  },
  computed: {
    ...mapGetters(['projectDetail']),
  },
  methods: {
    applicationNameByCityCode() {
      console.log('========', this.projectDetail);
      applicationNameByCityCode((this.projectDetail as any)?.ssCity).then(
        (res) => {
          console.log('获取城市文件', res);
          if (res.status === 200 && res.result) {
            this.redDocumentList = res.result;
            sessionStorage.setItem(
              'redDocumentList',
              JSON.stringify(res.result)
            );
          }
        }
      );
    },
  },
});
